
<!-- template for the modal component -->
<template>
  <transition name="modal">
    <div class="cali__modal-mask" role="dialog">
      <div class="cali__modal-dialog" role="document">
        <div class="cali__modal-content" :class="('size-' + modalSize)" :id="uniqueId">

          <div class="cali__modal-header">
            <slot name="header">
              <h4 class="cali__modal-title">{{ title || 'Default title' }}</h4>
            </slot>
            <button type="button" class="cali_modal-btn-close" @click="$emit('close')">
              <img class="cali__modal-close-modal" v-if="closeModalImage.src" :src="closeModalImage.src" :alt="closeModalImage.alt" />

              <div class="cali__modal-close-modal" v-else> <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/btn-close" alt=""> </div>
            </button>
          </div>

          <div class="cali__modal-body">
            <slot name="body">
              Default body
            </slot>
          </div>

          <div class="cali__modal-footer">
            <slot name="footer">
              <button type="button" class="cali__modal-btn-primary" @click="$emit('close')">OK</button>
              <button type="button" class="main-btn simple-btn" @click="$emit('close')"><i class="fal fa-times"></i> Close</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeModalImage: {
        type: Object,
        default: () => ({
            src: null,
            alt: null
        })
    },
    title: String,
    modalSize: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    uniqueId() {
        return 'modal__' + this?.$?.type?.__scopeId + '__' + this?.$?.uid
    }
  },
  // methods: {
  //   manageClick(e) {
  //       if (!document.getElementById(this.uniqueId).contains(e.target)){
  //           this.$emit('close');
  //       }
  //   }
  // },
  // created() {
  //   document.body.style.overflow='hidden';
  //
  //   setTimeout(() => {
  //       window.addEventListener('click', this.manageClick, true);
  //   }, 250)
  // },
  // beforeUnmount() {
  //   document.body.style.overflow='';
  //
  //   window.removeEventListener('click', this.manageClick, true);
  // }
}
</script>

<style lang="scss">
@import "styles.css";
.cali__modal-dialog{
  margin: 25px;
}
.cali__modal-header{
  border-bottom: 0;
}
.cali__modal-header{
  color: #FA39EB;
  font-size: 16px;
  h4{
    font-size: 16px;
    font-weight: 700;
  }
}
.cali_modal-btn-close{
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px !important;
  height: 30px !important;
  img{
    margin-right: 0;
    font-size: inherit !important;
    padding-right: 0;
  }
}
.cali__modal-close-modal{
  width: 30px !important;
  height: 30px !important;
}
</style>
